import { computed, Injectable, signal } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  AcaciumBreakpointsQueries,
  AcaciumDeviceEnum,
} from '@client-portal/enums/common';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private _deviceBreakpoints = new Map<string, AcaciumDeviceEnum>([
    [AcaciumBreakpointsQueries.mobile, AcaciumDeviceEnum.mobile],
    [AcaciumBreakpointsQueries.tablet, AcaciumDeviceEnum.tablet],
    [AcaciumBreakpointsQueries.desktop, AcaciumDeviceEnum.desktop],
  ]);

  public type = signal(AcaciumDeviceEnum.unknown);
  public isMobile = computed(() => this.type() === AcaciumDeviceEnum.mobile);
  public isMobileOrTablet = computed(
    () =>
      this.type() === AcaciumDeviceEnum.mobile ||
      this.type() === AcaciumDeviceEnum.tablet,
  );

  constructor(private _breakpointObserver: BreakpointObserver) {
    this._observeBreakpoints();
  }

  private _observeBreakpoints(): void {
    this._breakpointObserver
      .observe([...this._deviceBreakpoints.keys()])
      .pipe(
        map((result: BreakpointState) => {
          const breakpoints = result.breakpoints;
          for (const query of Object.keys(breakpoints)) {
            if (breakpoints[query]) {
              return (
                this._deviceBreakpoints.get(query) ?? AcaciumDeviceEnum.unknown
              );
            }
          }
          return AcaciumDeviceEnum.unknown;
        }),
        distinctUntilChanged(),
        takeUntilDestroyed(),
      )
      .subscribe((device) => {
        this.type.set(device);
      });
  }
}
